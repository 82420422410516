import React, { useEffect } from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';
import Icon from './Icon';
import { set } from 'date-fns';
import Accordion from './ServiceAccordion';
import Button from './Button';

const AccordionBase = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const AccordionHeaderBase = styled.button<{ isExpanded: boolean }>`
  width: 100%;
  display: flex;
  padding: 20px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Roboto, sans-serif;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  border-bottom: 1px solid #eef4fc;
  user-select: none;
  text-align: left;

  &:focus {
    outline: 2px solid #646a70;
    outline-offset: -2px;
  }

  &:hover {
    background-color: #f5f7fa;
  }
`;

const AccordionHeaderLabel = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
`;

const AccordionHeaderIcons = styled.div`
  display: flex;
  padding-left: 10px;
  width: 100px;
`;

const AccordionHeaderDescription = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #646a70;
  padding-top: 5px;
`;

const AccordionBodyBase = styled.div<{ selected?: boolean }>`
  overflow: hidden;
  font-family: Roboto, sans-serif;
  display: block;
  padding: 20px 20px;
  background-color: #eef4fc;
  box-sizing: border-box;
`;

const AccordionBodyContainer = styled.div<{ selected?: boolean }>`
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: ${props => (props.selected ? '2000px' : '0px')};
  box-sizing: border-box;
`;

const AccordionHeaderChevron = styled.span`
  float: right;
  display: flex;
  align-items: center;
`;

export interface AccordionItemProps {
  name: string;
  label: string;
  icons?: string[];
  content?: any;
  description?: string;
}

interface AccordionProps {
  values: AccordionItemProps[];
  onChange?: (value: AccordionItemProps) => void;
  defaultValue?: string | null;
}

const DrawerAccordion = ({ values, onChange, defaultValue }: AccordionProps) => {
  const [elValue, setElValue] = React.useState<string | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setElValue(defaultValue);
    }
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent, value: AccordionItemProps) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleClick(value);
    }
  };

  const handleClick = (value: AccordionItemProps) => {
    if (value.name === elValue) {
      setElValue(null);
      onChange &&
        onChange({
          name: '',
          label: '',
          icons: [],
          content: null,
        });
    } else {
      setElValue(value.name);
      onChange && onChange(value);
    }
  };

  return (
    <AccordionBase className="SeminargoAccordion" role="presentation">
      {values.map((value, index) => {
        const isExpanded = value.name === elValue;
        const headerId = `accordion-header-${value.name}`;
        const panelId = `accordion-panel-${value.name}`;

        return (
          <div key={index} className="SeminargoAccordionItem">
            <AccordionHeaderBase
              className="SeminargoAccordionHeader"
              aria-label={value.label + ' ' + value.description}
              onClick={() => handleClick(value)}
              onKeyDown={(e) => handleKeyDown(e, value)}
              aria-expanded={isExpanded}
              aria-controls={panelId}
              id={headerId}
              isExpanded={isExpanded}
              type="button"
            >
              <AccordionHeaderLabel className="SeminargoAccordionHeaderLabel">
                {value.label}
                <br />
                <AccordionHeaderDescription>{value.description}</AccordionHeaderDescription>
              </AccordionHeaderLabel>
              <AccordionHeaderChevron aria-hidden="true">
                <Icon name={isExpanded ? "chevrondown" : "chevronup"} />
              </AccordionHeaderChevron>
            </AccordionHeaderBase>
            <AccordionBodyContainer 
              selected={isExpanded}
              role="region"
              id={panelId}
              aria-labelledby={headerId}
              hidden={!isExpanded}
            >
              <AccordionBodyBase>{value.content}</AccordionBodyBase>
            </AccordionBodyContainer>
          </div>
        );
      })}
    </AccordionBase>
  );
};

export default DrawerAccordion;
