import React, { useEffect, useRef } from 'react';
import styled, { StyleSheetManager, ThemedStyledProps, css } from 'styled-components';
import { createPortal } from 'react-dom';
import { initial } from 'lodash';
import { createShadow, shadowRootFocusListener } from '../helper';

const BaseDrawerOverlay = styled.div<{ open?: boolean }>`
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  display: ${props => (props.open ? 'block' : 'none')};
  height: 100%;
  z-index: 9997;
`;

const BaseDrawer = styled.div<{ open?: boolean; fullScreen: boolean }>`
  position: fixed;
  top: 0px;
  right: ${props => (props.open ? '0px' : props.fullScreen ? '-100%' : '-450px')}};
  display: block;
  background-color: #fff;
  width: ${props => (props.fullScreen ? '100%' : '450px')};
  height: 100%;
  z-index: 9998;
  box-sizing: border-box;
  text-align: left;
  transition: right 0.7s ease-in-out;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding-bottom: 100px;
  &:focus {
    outline: none;
  }
  
  /* Ensure content is readable by screen readers */
  &[aria-hidden="false"] {
    visibility: visible;
  }
`;

const BaseDrawerBottom = styled.div<{ open?: boolean; fullScreen: boolean }>`
  position: fixed;
  bottom: 0px;
  right: ${props => (props.open ? '0px' : props.fullScreen ? '-100%' : '-450px')}};
  display: block;
  background-color: #fff;
  border-top: 1px solid #AEB2B4;
  width: ${props => (props.fullScreen ? '100%' : '450px')};
  z-index: 9999;
  box-sizing: border-box;
  text-align: left;
  transition: right 0.7s ease-in-out;
  padding: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 16px;
  color: #000;
  
  &:focus {
    outline: 2px solid #646a70;
    border-radius: 4px;
  }
`;

const DrawerContent = styled.div`
  position: relative;
  height: 100%;
`;

interface DrawerProps {
  open: boolean;
  onOverlayClick?: () => void;
  children?: any;
  fullscreen?: boolean;
  bottomContent?: any;
  title?: string;
}

const Drawer = ({ open, children, bottomContent, onOverlayClick, fullscreen, title = 'Dialog' }: DrawerProps) => {
  const [shadow, setShadow] = React.useState<ShadowRoot | null>(null);
  const drawerRef = useRef<HTMLDivElement>(null);
  const previousFocusRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const shadow = createShadow(document.body);
    setShadow(shadow);
  }, []);

  useEffect(() => {
    if (open) {
      previousFocusRef.current = document.activeElement as HTMLElement;
      document.body.style.overflow = 'hidden';
      
      // Focus the drawer itself
      setTimeout(() => {
        drawerRef.current?.focus();
        drawerRef.current?.addEventListener('focus', shadowRootFocusListener, true);
      }, 200);
    } else {
      previousFocusRef.current?.focus();
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape' && onOverlayClick) {
      onOverlayClick();
    }
  };

  if (!open || !shadow) {
    return null;
  }

  return createPortal(
    <StyleSheetManager target={shadow}>
      <div style={{ all: 'initial' }} ref={drawerRef} tabIndex={1}>
        <BaseDrawerOverlay 
          open={true} 
          onClick={() => onOverlayClick && onOverlayClick()} 
          className="SeminargoDrawerOverlay"
          aria-hidden="true"
        />
        <BaseDrawer 
          fullScreen={fullscreen || false} 
          open={true} 
          className="SeminargoDrawer"
          role="dialog"
          aria-modal="true"
          aria-label={title}
          onKeyDown={handleKeyDown}
          tabIndex={1}
        >
          <DrawerContent>
            <CloseButton
              onClick={() => onOverlayClick && onOverlayClick()}
              aria-label="Fenster schließen"
            >
              X
            </CloseButton>
              {children}
          </DrawerContent>
        </BaseDrawer>
        <BaseDrawerBottom 
          fullScreen={fullscreen || false} 
          open={true} 
          className="SeminargoDrawerBottom"
          tabIndex={-1}
        >
          {bottomContent}
        </BaseDrawerBottom>
      </div>
    </StyleSheetManager>,
    shadow
  );
};

export default Drawer;
