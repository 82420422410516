import React, { useEffect } from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const AccordionBase = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const AccordionHeaderBase = styled.button<{ isExpanded: boolean }>`
  width: 100%;
  display: flex;
  padding: 20px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Roboto, sans-serif;
  align-items: center;
  cursor: pointer;
  border: none;
  background: none;
  border-bottom: 1px solid #eef4fc;
  user-select: none;
  text-align: left;

  &:focus {
    outline: 2px solid #646a70;
    outline-offset: -2px;
  }

  &:hover {
    background-color: #f5f7fa;
  }
`;

const AccordionHeaderLabel = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
`;

const AccordionHeaderDescription = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #646a70;
  padding-top: 5px;
`;

const AccordionBodyBase = styled.div`
  overflow: hidden;
  font-family: Roboto, sans-serif;
  display: block;
  padding: 20px 20px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
`;

const AccordionBodyContainer = styled.div<{ selected?: boolean }>`
  overflow: hidden;
  overflow-x: scroll;
  transition: max-height 0.3s ease-in-out;
  max-height: ${props => (props.selected ? '2000px' : '0px')};
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const AccordionHeaderChevron = styled.span`
  display: flex;
  align-items: center;
`;

export interface AccordionItemProps {
  name: string;
  label: string;
  icons?: string[];
  content?: React.ReactNode;
  description?: string;
}

interface AccordionProps {
  values: AccordionItemProps[];
  onChange?: (value: AccordionItemProps) => void;
  defaultValue?: string;
}

const ConfirmationAccordion = ({ values, onChange }: AccordionProps) => {
  const [elValue, setElValue] = React.useState<string | null>(null);

  const handleClick = (value: AccordionItemProps) => {
    if (value.name === elValue) {
      setElValue(null);
      onChange?.({
        name: '',
        label: '',
        icons: [],
        content: null,
      });
    } else {
      setElValue(value.name);
      onChange?.(value);
    }
  };

  return (
    <AccordionBase role="presentation">
      {values.map((value, index) => {
        const isExpanded = value.name === elValue;
        const headerId = `accordion-header-${value.name}`;
        const panelId = `accordion-panel-${value.name}`;

        return (
          <div key={index}>
            <AccordionHeaderBase
              type="button"
              aria-label={value.label}
              isExpanded={isExpanded}
              onClick={() => handleClick(value)}
              aria-expanded={isExpanded}
              aria-controls={panelId}
              id={headerId}
            >
              <AccordionHeaderLabel>
                {value.label}
                <br />
                <AccordionHeaderDescription>{value.description}</AccordionHeaderDescription>
              </AccordionHeaderLabel>
              <AccordionHeaderChevron aria-hidden="true">
                <Icon name={isExpanded ? "chevrondown" : "chevronup"} />
              </AccordionHeaderChevron>
            </AccordionHeaderBase>
            <AccordionBodyContainer 
              selected={isExpanded}
              role="region"
              id={panelId}
              aria-labelledby={headerId}
              hidden={!isExpanded}
            >
              <AccordionBodyBase>{value.content}</AccordionBodyBase>
            </AccordionBodyContainer>
          </div>
        );
      })}
    </AccordionBase>
  );
};

export default ConfirmationAccordion;
