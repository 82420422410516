import React from 'react';
import Divider from '../../Divider';
import NumField from '../../NumField';
import Checkbox from '../../Checkbox';
import DaySwitch from '../../DaySwitch';
import { IFormState } from '../Capsule';
import { ContentRow, CapsuleTabContentBody, CapsuleTabContentHeader } from '../CapsuleComponents';
import { addDays } from 'date-fns/fp';
import { CalcPriceOutput, QuickPriceCalculatorType } from '../../../semshared/pricelist/quickprice';
import { AnyRecord } from 'dns';

interface ExtrasSectionProps {
  formState: IFormState;
  setFormState: (formState: IFormState) => void;
  quickPriceCalculator: QuickPriceCalculatorType | null;
}

export const hasFormStateAnyVisibleExtras = (formState: IFormState) => {
  for (const [dayIndex, day] of formState.days.entries()) {
    if (hasFormStateVisibleExtrasForDay(formState, dayIndex)) return true;
  }
  return false;
};
export const hasFormStateAnyCheckedExtras = (formState: IFormState) => {
  for (const [dayIndex, day] of formState.days.entries()) {
    if (hasFormStateCheckedExtrasForDay(formState, dayIndex)) return true;
  }
  return false;
};

export const hasFormStateVisibleExtrasForDay = (formState: IFormState, day: number) => {
  return formState.days[day].addonFacilities.filter(f => f.visible).length > 0 || formState.days[day].addonProducts.filter(p => p.visible).length > 0;
};
export const hasFormStateCheckedExtrasForDay = (formState: IFormState, day: number) => {
  return (
    formState.days[day].addonFacilities.filter(f => f.visible && f.checked).length > 0 ||
    formState.days[day].addonProducts.filter(p => p.visible && p.checked).length > 0
  );
};

const ExtrasSection = ({ formState, setFormState, quickPriceCalculator }: ExtrasSectionProps) => {
  const [extrasDay, setExtrasDay] = React.useState(0);
  const date = addDays(extrasDay, formState.start);

  const _getFacilityAddonPrice = (date: Date, facilityAddon: any) => {
    if (quickPriceCalculator) {
      const calcOutput = quickPriceCalculator.calculatePrice(
        {
          days: [
            {
              addonFacilities: [
                {
                  ...facilityAddon,
                  count: 1,
                },
              ],
              day: 0,
              occupancy: [],
              overnightGuests: formState.days[extrasDay].overnightGuests,
              seating: [],
              totalGuests: formState.days[extrasDay].totalGuests,
            },
          ],
          endDate: date,
          language: 'de',
          prevdayGuests: formState.prevdayGuests,
          serviceTypeSku: formState.service.sku,
          startDate: date,
        },
        {
          addonsOnly: true,
        },
      );
      return calcOutput.totalPriceNet.toFixed(2).toString().replace('.', ',');
    }
    return 0;
  };

  const _getProductAddonPrice = (date: Date, productAddon: any) => {
    if (quickPriceCalculator) {
      const calcOutput = quickPriceCalculator.calculatePrice(
        {
          days: [
            {
              addonProducts: [
                {
                  ...productAddon,
                  count: 1,
                },
              ],
              day: 0,
              occupancy: [],
              overnightGuests: formState.days[extrasDay].overnightGuests,
              seating: [],
              totalGuests: formState.days[extrasDay].totalGuests,
            },
          ],
          endDate: date,
          language: 'de',
          prevdayGuests: 0,
          serviceTypeSku: formState.service.sku,
          startDate: date,
        },
        {
          addonsOnly: true,
        },
      );

      let price = calcOutput.totalPriceNet.toFixed(2);
      if ((productAddon.recurring === 'BYGUEST' || productAddon.recurring === 'BYGUESTANDDAY') && formState.days[extrasDay].totalGuests > 0) {
        price = (calcOutput.totalPriceNet / formState.days[extrasDay].totalGuests).toFixed(2);
      }

      return price.toString().replace('.', ',');
    }
    return 0;
  };

  const _getRecurringText = (recurring: string) => {
    switch (recurring) {
      case 'BYCOUNT':
        return 'pro Stück';
      case 'BYDAY':
        return 'pro Tag';
      case 'BYGUEST':
        return 'pro Gast';
      case 'BYGUESTANDDAY':
        return 'pro Gast';
      case 'BYROOM':
        return 'pro Zimmer';
      case 'BYROOMANDDAY':
        return 'pro Zimmer';
      default:
        return recurring;
    }
  };

  return (
    <>
      <ContentRow>
        <DaySwitch value={extrasDay} max={formState.days.length - 1} onChange={v => setExtrasDay(v)} date={date} />
      </ContentRow>
      {formState.days[extrasDay].addonFacilities.filter(f => f.visible).length > 0 && (
        <>
          <CapsuleTabContentHeader>Ausstattung</CapsuleTabContentHeader>
          <CapsuleTabContentBody>
            {formState.days[extrasDay].addonFacilities.map(
              (facility, index) =>
                facility.visible && (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        display: 'flex',
                        fontFamily: 'Roboto, sans-serif',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'right',
                          paddingRight: 10,
                        }}
                      >
                        <Checkbox
                          aria-label={facility.header + ': ' + facility.details + ' ' + _getFacilityAddonPrice(date, facility) + ' Euro inkl. Mwst. ' + _getRecurringText(facility.recurring)}
                          label={<div
                            style={{
                              textAlign: 'left',
                              flexGrow: 1,
                            }}
                          >
                            <strong>{facility.header}</strong>
                            <div>{facility.details}</div>
                            <div
                              style={{
                                fontSize: 14,
                                color: '#333',
                                fontWeight: 'bold',
                                marginTop: 10,
                              }}
                            >
                              {_getFacilityAddonPrice(date, facility)} € + MwSt.{' '}
                              <span style={{ fontWeight: 'normal' }}> ({_getRecurringText(facility.recurring)})</span>
                            </div>
                          </div>}
                          type="checkbox"
                          name={facility.sku}
                          checked={facility.checked}
                          onChange={() => {
                            formState.days[extrasDay].addonFacilities[index].checked = !facility.checked;
                            setFormState({ ...formState });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          paddingRight: 0,
                          paddingLeft: 10,
                          alignSelf: 'center',
                          width: 140,
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {facility.recurring === 'BYCOUNT' && (
                          <NumField
                            ariaLabelPrefix="Ausstattung"
                            value={facility.count}
                            disabled={!facility.checked}
                            min={1}
                            onChange={value => {
                              formState.days[extrasDay].addonFacilities[index].count = value;
                              setFormState({ ...formState });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <Divider />
                  </React.Fragment>
                ),
            )}
          </CapsuleTabContentBody>
        </>
      )}
      {formState.days[extrasDay].addonProducts.filter(p => p.visible).length > 0 && (
        <>
          <CapsuleTabContentHeader>Zusatzleistungen</CapsuleTabContentHeader>
          <CapsuleTabContentBody>
            {formState.days[extrasDay].addonProducts.map(
              (product, index) =>
                product.visible &&
                product.isAddon && (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontFamily: 'Roboto, sans-serif',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          textAlign: 'right',
                          paddingRight: 10,
                        }}
                      >
                        <Checkbox
                          aria-label={product.header + ': ' + product.details + ' ' + _getProductAddonPrice(date, product) + ' Euro inkl. Mwst. ' + _getRecurringText(product.recurring)}
                          label={<div
                            style={{
                              textAlign: 'left',
                              flexGrow: 1,
                            }}
                          >
                            <strong>{product.header}</strong>
                            <div>{product.details}</div>
                            <div
                              style={{
                                fontSize: 14,
                                color: '#333',
                                fontWeight: 'bold',
                                marginTop: 10,
                              }}
                            >
                              {_getProductAddonPrice(date, product)} € + MwSt.{' '}
                              <span style={{ fontWeight: 'normal' }}> ({_getRecurringText(product.recurring)})</span>
                            </div>
                          </div>}
                          type="checkbox"
                          name={product.sku}
                          checked={product.checked}
                          onChange={() => {
                            formState.days[extrasDay].addonProducts[index].checked = !product.checked;
                            setFormState({ ...formState });
                          }}
                        />
                      </div>
                      <div
                        style={{
                          paddingRight: 0,
                          paddingLeft: 10,
                          alignSelf: 'center',
                          width: 140,
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {product.recurring === 'BYCOUNT' && (
                          <NumField
                            ariaLabelPrefix="Zusatzleistung"
                            value={product.count}
                            disabled={!product.checked}
                            min={1}
                            onChange={value => {
                              formState.days[extrasDay].addonProducts[index].count = value;
                              setFormState({ ...formState });
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <Divider />
                  </React.Fragment>
                ),
            )}
          </CapsuleTabContentBody>
        </>
      )}
    </>
  );
};

export default ExtrasSection;
