import React, { useEffect } from 'react';
import styled, { ThemedStyledProps, css } from 'styled-components';
import Icon from './Icon';
import { set } from 'date-fns';
import Accordion from './ServiceAccordion';
import Button from './Button';
import { el } from 'date-fns/locale';
import { OtelManager } from '../api/otel';

const AccordionBase = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const AccordionHeaderBase = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Roboto, sans-serif;
  align-items: center;
  border-bottom: 1px solid #eef4fc;
  user-select: none;
  cursor: pointer;
`;

const AccordionHeaderLabel = styled.div<{ selected?: boolean }>`
  width: 100%;
  font-weight: bold;
  font-size: 14px;
  color: ${props => (props.selected ? '#000' : '#ccc')};
`;

const AccordionHeaderIcons = styled.div`
  display: flex;
  padding-left: 10px;
  width: 100px;
`;

const AccordionHeaderDescription = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #646a70;
  padding-top: 5px;
`;

const AccordionBodyBase = styled.div<{ selected?: boolean }>`
  overflow: hidden;
  font-family: Roboto, sans-serif;
  display: block;
  padding: 20px 20px;
  background-color: #eef4fc;
  box-sizing: border-box;
`;

const AccordionBodyContainer = styled.div<{ selected?: boolean }>`
  overflow: hidden;
  transition: max-height 0.1s;
  max-height: ${props => (props.selected ? 'auto' : '0px')};
  display: ${props => (props.selected ? 'block' : 'none')};
  box-sizing: border-box;
`;

const AccordionHeaderChevron = styled.div`
  float: right;
`;

export interface AccordionItemProps {
  name: string;
  label: string;
  icons?: string[];
  content?: any;
  description?: string;
  onNextClicked?: ((next: () => void) => void) | null;
  onLoad?: (() => void) | null;
}

interface AccordionProps {
  values: AccordionItemProps[];
  onChange?: (value: AccordionItemProps) => void;
  defaultValue?: string | null;
  color: string;
  isMobileSize: () => boolean;
  endButton: React.ReactNode;
  endSection: React.ReactNode;
  startButton: React.ReactNode;
  errors: any;
  closeFunc?: () => void;
}

const WizardAccordion = ({
  values,
  onChange,
  defaultValue,
  color,
  isMobileSize,
  endButton,
  endSection,
  startButton,
  errors,
  closeFunc,
}: AccordionProps) => {
  const [elValue, setElValue] = React.useState<string | null>(null);
  const [elIndex, setElIndex] = React.useState<number | null>(0);
  const [elCollapsed, setElCollapsed] = React.useState<boolean>(true);
  const [endReachedOnce, setEndReachedOnce] = React.useState<boolean>(false);

  useEffect(() => {
    if (elIndex !== null) {
      OtelManager.getInstance().widgetSetCurrentView('form-' + values[elIndex].name);
      setElValue(values[elIndex].name);
      if (onChange) {
        onChange(values[elIndex]);
      }
    } else {
      setElValue(null);
    }
  }, [elIndex]);

  return (
    <>
      <AccordionBase className="SeminargoAccordion">
        {values.map((value, index) => (
          <div key={index} className="SeminargoAccordionItem">
            <AccordionHeaderBase
              aria-label={value.label}
              className="SeminargoAccordionHeader"
              tabIndex={0}
              onClick={() => {
                if (index === values.length - 1) setEndReachedOnce(true);
                if (index === elIndex) {
                  setElIndex(null);
                } else {
                  setElIndex(index);
                }
              }}
              onKeyUp={e => {
                if (e.key === 'Enter') {
                  if (index === values.length - 1) setEndReachedOnce(true);
                  if (index === elIndex) {
                    setElIndex(null);
                  } else {
                    setElIndex(index);
                  }
                }
              }}
            >
              <AccordionHeaderLabel selected={true} className="SeminargoAccordionHeaderLabel">
                {value.label}
                <br />
                <AccordionHeaderDescription>{value.description}</AccordionHeaderDescription>
              </AccordionHeaderLabel>
              <AccordionHeaderChevron>
                {index === 0 && <Icon name="checkmark" />}
                {index === 1 && endReachedOnce && Object.keys(errors).length === 0 && <Icon name="checkmark" />}
                {index === 1 && Object.keys(errors).length > 0 && <Icon name="error" />}
              </AccordionHeaderChevron>
            </AccordionHeaderBase>
            <AccordionBodyContainer selected={value.name === elValue}>
              <AccordionBodyBase>{value.content}</AccordionBodyBase>
            </AccordionBodyContainer>
          </div>
        ))}
      </AccordionBase>
      {elIndex === values.length - 1 && endSection}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: isMobileSize() ? 'column' : 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: 10,
          }}
        >
          {elIndex === 0 && startButton}
          {elIndex !== null && elIndex > 0 && (
            <Button
              color={color}
              iconPosition="left"
              aria-label="Zurück"
              fullWidth={isMobileSize()}
              onClick={() => {
                if (elIndex !== null) {
                  if (elIndex > 0) {
                    setElIndex(elIndex - 1);
                  }
                }
              }}
            >
              <Icon color={color} name="chevronleft" /> Zurück
            </Button>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: 10,
          }}
        >
          {elIndex !== values.length - 1 && (
            <Button
              color={color}
              filled={true}
              iconPosition="right"
              aria-label="Weiter" 
              fullWidth={isMobileSize()}
              onKeyDown={e => {
                if (e.key === 'Tab') {
                  closeFunc && closeFunc();
                }
              }}
              onClick={() => {
                if (elIndex === 1) setEndReachedOnce(true);
                const next = () => {
                  if (elIndex !== null) {
                    if (elIndex < values.length - 1) {
                      setElIndex(elIndex + 1);
                    }
                  }
                };
                if (elIndex && values[elIndex].onNextClicked) {
                  values[elIndex].onNextClicked!(next);
                } else {
                  next();
                }
              }}
            >
              Weiter <Icon color="white" name="chevronright" />
            </Button>
          )}
          {elIndex === values.length - 1 && endButton}
        </div>
      </div>
    </>
  );
};

export default WizardAccordion;
