import React from 'react';
import styled from 'styled-components';

const BaseInput = styled.input`
  position: relative;
  left: 15px;
  top: -4px;
  z-index: 0;
  -webkit-appearance: none;
  height: 0px;
  width: 0px;
  & + label:before {
    width: 15px;
    height: 15px;
    border-radius: 5px;
    border: 2px solid #000;
    background-color: #fff;
    display: block;
    content: '';
    float: left;
    margin-right: 15px;
    z-index: 5;
    position: relative;
    cursor: pointer;
  }
  &:checked + label:before {
    box-shadow: inset 0px 0px 0px 3px #fff;
    background-color: #000;
  }
  &:focus + label:before {
    border-width: 3px;
  }
  & + label {
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow-wrap: anywhere;
  }
`;

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: React.ReactNode;
  'aria-label': string;
}

const Checkbox = (inputProps: CheckboxProps) => {
  return (
    <>
      <BaseInput
        onKeyUp={e => {
          if (e.key === 'Enter' && inputProps.onChange) {
            inputProps.onChange(e as unknown as React.ChangeEvent<HTMLInputElement>);
          }
        }}
        tabIndex={0}
        type="checkbox"
        id={inputProps.name}
        {...inputProps}
      />
      <label htmlFor={inputProps.name}>{inputProps.label}</label>
    </>
  );
};

export default Checkbox;
